import styles from "./Collection.module.css";
import Header from "../../components/header/Header";
import React, {useEffect, useState} from "react";
import {Button, Col, Row, Space,} from "antd";
import avatarImage from '../../assets/avatar.svg'

import {TicketsTable} from "../../components/ticketsTable/TicketsTable";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getProfile} from "../../solutions/controllers/UserController";
import {IUser} from "../../solutions/interfaces/IUser";
import {getTicketsByLoggedUser} from "../../solutions/controllers/CollectionController";
export default function Collection(){
    const [isNftVisible, setIsNftVisible] = useState(false);
    const [isTickets, setIsTicketsVisible] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profile, setProfile] = useState<IUser>();
    const [ticketList, setTicketList] = useState([]);


    const auth = useSelector((state : any) => state.auth);

    useEffect(() => {
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);
            getTicketsByLoggedUser(setTicketList, auth, () => {}, dispatch)
        }
    }, [auth]);

    function handleTable(tableToWatch:string) {
        if (tableToWatch==='tickets'){
            setIsTicketsVisible(true);
            setIsNftVisible(false);
        }else{
            setIsTicketsVisible(false);
            setIsNftVisible(true);
        }
    }

    return (
      <>
          <div className={styles.background}>
              <Header/>
              <Row >
                  <Col xs={{offset:2, span:8}} sm={{offset:2, span:6}} md={{offset:2, span:5}} lg={{offset:2, span:4}} xl={{offset:2, span:3}} xxl={{offset:2, span:2}}  style={{marginTop:5}}>
                      <img src={avatarImage}></img>
                  </Col>
                  <Col xs={{offset:0, span:12}} sm={{offset:0, span:10}} md={{offset:0, span:6}} lg={{offset:0, span:5}} xl={{offset:0, span:4}} xxl={{offset:0, span:3}} style={{marginLeft:10}}>
                      <Space direction={"vertical"}>
                          <h2 style={{color:'white'}}>Mis colecciones</h2>
                          <h2 style={{color:'white'}}>Hola {profile ? profile.name : "Invitado"}!</h2>
                      </Space>
                  </Col>
              </Row>
              <div >
                  <Row justify={'center'} style={{marginTop:15}} >
                      <Col span={10}>
                          <Button className={isTickets?styles.buttonSelected:styles.buttonNotSelected} onClick={()=>handleTable('tickets')}>Entrada</Button>

                      </Col>
                      <Col span={10} style={{marginLeft:5}}>
                          <Button className={isNftVisible?styles.buttonSelected:styles.buttonNotSelected} onClick={()=>handleTable('nft')}>NFT</Button>
                      </Col>
                  </Row>
                  {isTickets?(
                      <Row justify={'center'} style={{marginTop:5}}>
                          <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} >
                              <TicketsTable tickets={ticketList}></TicketsTable>
                          </Col>
                      </Row>
                  ):''}

              </div>
          </div>
      </>
    );
}
export function NftTable(){
    const [data, setData] = useState()
    return (
        <>
        {data?(
            <div>

            </div>
        ):''}
    </>
    );
}
