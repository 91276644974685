import {IEventDetails} from "../interfaces/IEventDetails";
import React from "react";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import { Dispatch, AnyAction } from "redux";
import {ITicket} from "../interfaces/ITicket";
import {ITicketQuantities} from "../interfaces/ITicketQuantities";
import {generateUuidAndExecuteCallback} from "../Utils";
import {Seat} from "../../components/seatReservation/model/Seat";
import {message} from "antd";

export function loadEventDetail(eventId: string, setEvent: React.Dispatch<React.SetStateAction<IEventDetails | undefined>>, dispatch: Dispatch<AnyAction>) {
    let url = rootServiceURL() + "fullgraph/event/getEventDetail/"+eventId;

    let rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
    rc.doGet((results: IEventDetails) => {
        if(results){
            setEvent(results)
        }
        else{
            setEvent(undefined)
        }
    })
}

export function areNumberedInTicketList(setAreNumbered:React.Dispatch<boolean>, ticketList:ITicket[]){
    setAreNumbered(ticketList.some((t)=> t.numbered));
}

export function loadTicketEventByDate(eventDateId: number, previousData:ITicketQuantities, setTicketEventByDates: React.Dispatch<React.SetStateAction<ITicketQuantities | undefined>>, dispatch: any) {
    let url = rootServiceURL() + "completegraph/event/getTicketEvents/"+eventDateId;

    let rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
    rc.doGet((results: any[]) => {
        if(results){
            //@ts-ignore
            setTicketEventByDates({...previousData, [eventDateId]: results.map(r => {
                    return ({
                        ...r,
                        id: r.ticketeventid
                    })
                })});

        }
        else{
            //@ts-ignore
            setTicketEventByDates({...previousData, [eventDateId]: undefined})
        }
    })
}

export function checkTicketsAvailableAndGoToBuy(ticketQuantities:ITicketQuantities, numberedTickets: Seat[], callback: any, dispatch: any){
    generateUuidAndExecuteCallback((uuid)=>{
        if(!uuid){
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        }
        else{
            let bookTicket : any[] = [];
            Object.keys(ticketQuantities).forEach(ticketeventid => bookTicket.push({
                ticketeventid: ticketeventid,
                quantity: ticketQuantities[parseInt(ticketeventid)]
            }))

            const bookTicketInfoDTO = {
                bookTicketList: bookTicket,
                seatTicketeventList: numberedTickets.map(value => ({
                    ticketEventId: value.ticketeventid,
                    seatId: value.id
                }))
            }

            if(numberedTickets.some((t => t.ticketeventid == null))){
                message.error("Asientos sin precio definido, contacte con el servidio técnico")
                return;
            }
            console.log("Transactionid", uuid)
            console.log("bookTicketInfoDTO", bookTicketInfoDTO)

            let url = rootServiceURL() + "completegraph/event/bookTickets/"+uuid;
            let rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
            rc.doPost(bookTicketInfoDTO, (results: any) => {
                if(rc.isSuccess()){
                    callback && callback(uuid);
                }
                else{
                    message.error("Error reservando tickets "+rc.getErrors())
                }
            }, false)
        }
    });
}

