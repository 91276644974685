import styles from './Header.module.css';
import {Row, Col, MenuProps, Menu, Input, Image, Button, Space, message} from "antd";
import {useEffect, useState} from "react";
import classNames from 'classnames';
import Search from "antd/es/input/Search";
import React from 'react';
import { SearchOutlined } from '@ant-design/icons'; // Importar el ícono de lupa de Ant Design
import searchImage from '../../assets/ic_outline-search.png';
import filterImage from '../../assets/lucide_filter.png';

import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../solutions/controllers/UserController";
import {IUser} from "../../solutions/interfaces/IUser";
import {useNavigate} from "react-router-dom";
import {login, logout} from "../../Controller/redux/auth/actions";
const items: MenuProps['items'] = [
    {
        key: 'country',
        label:'País',
        children:[
            {
                key:'spain',
                label:'España'
            }
        ],
    },
    {
        label: 'Planes',
        key: 'events',
    },
    {
        label: 'Música',
        key: 'music',
    },
    {
        label: 'Mi colección',
        key: 'myCollection',
    },

];

export default function Header(){
    const [current, setCurrent] = useState('events');
    const [isModalOpen, setIsmodalOpen] = useState(false);
    const [profile, setProfile] = useState<IUser>();
    const dispatch = useDispatch();
    //@ts-ignore
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("UseEffect auht", auth)
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);
        }
        else {
            setProfile(undefined);
        }
    }, [auth]);

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    function onSearch(){
    }

    function onLogOut() {
        //@ts-ignore
        dispatch(logout())
        navigate("/")
    }

    function goToLoginRegister(){
        navigate("/loginRegister")
    }

    function goToMyCollection(){
        navigate("/collection")
    }

    return (
            <Row justify={'center'}>
                <Col xs={{span: 12}} sm={{span:12}} md={4} lg={2} xl={2} xxl={2} >
                    <a onClick={() => navigate("/")}><h1 className={styles.title}>TIXXLAB</h1></a>
                </Col>
                <Col xs={{span: 12}} sm={{span:12}} md={0} lg={0} xl={0} xxl={0}>
                    <Space direction={"horizontal"}>
                        {profile ?
                            <>
                                <Button onClick={goToMyCollection} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Mis entradas
                                </Button>
                                <Button onClick={onLogOut} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Salir
                                </Button>
                            </>
                            :
                            <>
                                <Button onClick={goToLoginRegister} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Login/Registro
                                </Button>
                            </>
                        }
                    </Space>
                </Col>
                <Col xs={{span: 24}} sm={{span:24}} md={12} lg={12} xl={12} xxl={12}  >
                    <div className={styles.searchContainer}>
                        <button  className={styles.searchButton} >
                            <img width={32} height={32} src={filterImage} alt="Filter" />
                        </button>
                        <button  className={styles.searchButton} >
                            <img width={32} height={32} src={searchImage} alt="Search" />
                        </button>
                        <Input className={styles.search} placeholder="" />
                    </div>
                </Col>
                <Col xs={{span: 0}} sm={{span:0}} md={{span:7, offset:1}} lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} >
                    <Space direction={"horizontal"}>
                        {profile ?
                            <>
                                <Button onClick={goToMyCollection} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Mis entradas
                                </Button>
                                <Button onClick={onLogOut} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Salir
                                </Button>
                            </>
                            :
                            <>
                                <Button onClick={goToLoginRegister} type={'default'} style={{borderRadius:20, backgroundColor:'#1CEDAE', borderColor:'#1CEDAE'}}>Login/Registro
                                </Button>
                            </>
                        }
                    </Space>
                </Col>
            </Row>
    );
    /*return (
      <div className={classNames( styles.header)}>
          <LoginRegisterModal isModalOpen={isModalOpen} setIsModalOpen={setIsmodalOpen}></LoginRegisterModal>
        <Row >
            <Col xs={{span:5, offset:0}} sm={5}  md={5} lg={6} xl={4} xxl={3} >
                <img className={styles.logo} alt="logo" src={"/logo-front.png"} />
            </Col>

            <Col  xs={{span:18, offset:0}} sm={{span:15, offset:1}} md={{offset:1, span:15}} lg={{offset:2, span:10}} xl={{offset:1, span:9}} xxl={{offset:1, span:7}} style={{marginTop:20}}>
                <Menu className={styles.menu}
                      onClick={onClick}
                      selectedKeys={[current]}
                      mode="horizontal"
                      items={items}


                />
            </Col>
            <Col xs={{span:15, offset:1}} sm={{span:15, offset:1}}  md={{offset:5, span:10}} lg={{offset:6, span:10}} xl={{offset:1, span:5}} xxl={{offset:3, span:6}}  style={{marginTop:30}}>
                <Search
                    placeholder="Escribe el nombre de lo que estás buscando"
                    allowClear
                    onSearch={onSearch}
                    className={styles.search} />

            </Col>
            <Col  xs={{offset:0, span:2}} md={{offset:0, span:1}} lg={{offset:0, span:1}} xl={{offset:0, span:1}} xxl={{offset:0, span:1}} style={{marginLeft:30, marginTop:30}}>
                <img src={'/filter.svg'} height="32px" alt={'filter'}/>
            </Col>
            <Col xs={{offset:0, span:2}} md={{offset:0, span:1}} lg={{offset:0, span:1}} xl={{offset:0, span:1}} xxl={{offset:0, span:1}} style={{marginLeft:20, marginTop:30}}>
                <img src={'/profile.svg'} height="32px" alt={'profile'} onClick={()=>setIsmodalOpen(true)}/>
            </Col>
            <Col xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={{offset:5, span:16}} lg={{offset:6, span:12}} xl={{offset:8, span:10}} xxl={{offset:8, span:10}} style={{marginTop:0}}>
                <h1 style={{color:'white'}}>
                    Los mejores eventos en un único lugar
                </h1>
            </Col>
        </Row >
      </div>
    );*/
}