import {IEventDate} from "./interfaces/IEventDate";
import React from "react";
import {Md5} from "ts-md5";

export function formateDatesToString(dates: IEventDate[], setEventDateFormatted : React.Dispatch<React.SetStateAction<string>> ){
    let formattedDate = '';
    const dateOptions = { day: 'numeric', month: 'long' };
    const monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    if (dates.length===1){
        // @ts-ignore
        formattedDate = new Date(dates[0].date).toLocaleDateString('es-ES',dateOptions);
        setEventDateFormatted(formattedDate);
    }else if(dates.length===2){
        formattedDate = new Date(dates[0].date).getDay() +', '+new Date(dates[1].date).getDay()+' de ';
        formattedDate+=monthNames[new Date(dates[0].date).getMonth()]
        setEventDateFormatted(formattedDate);
    }else if(dates.length>2){
        const startDay = new Date(dates[0].date).getDate();
        const endDay = new Date(dates[dates.length - 1].date).getDate();
        const monthName = monthNames[new Date(dates[0].date).getMonth()];
        formattedDate = `${startDay}-${endDay} de ${monthName}`;
        setEventDateFormatted(formattedDate);
    }
}

export function formatDateToString(date: Date){
    return new Date(date).toLocaleDateString('es-ES', {month: "2-digit", day: "2-digit",  year: "numeric"})
}

export function formatHourToString(date: Date){
    return new Date(date).toLocaleTimeString('es-ES', {hour: '2-digit', minute:'2-digit'})
}
export function generateUuidAndExecuteCallback(callback: (arg0: string | null) => any) {
    try {

        let frontId = localStorage.getItem('frontId');
        if(frontId == null){
            frontId = Math.random().toString() +
                Math.random().toString() +
                Math.random().toString();
            localStorage.setItem('frontId', frontId);
        }
        console.log("Recuperado frontId "+frontId)
        callback && callback(frontId);
    } catch (error) {
        console.error("Error recuperando frontId" ,error)
        callback && callback(null)
    }
}

export function deleteUuid() {
    localStorage.removeItem('frontId');
}

export const encryptMD5 = (text: string) : string => {
    return Md5.hashStr(text);
}