import {ITicketQuantities} from "../interfaces/ITicketQuantities";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {ITicket} from "../interfaces/ITicket";
import {ITicketInfo} from "../interfaces/ITicketInfo";
import {IUser} from "../interfaces/IUser";
import {deleteUuid, generateUuidAndExecuteCallback} from "../Utils";
import dayjs from "dayjs";
import {Seat} from "../../components/seatReservation/model/Seat";

const taxes: number = 5;

export function getTaxes() {
    let tax: number = 5;
    return tax;
}

export function calculateTicketsNumber(ticketQuantities: ITicketQuantities): number {
    let result: number = 0;
    let quantity: number = 0;
    for (const ticketId in ticketQuantities) {
        quantity = ticketQuantities[ticketId];
        if (quantity > 0) {
            result += quantity;
        }
    }
    return result;
}

export function updateTicketQuantities(ticketId: number, value: number, setTicketQuantities: React.Dispatch<React.SetStateAction<ITicketQuantities>>) {
    setTicketQuantities((prevQuantities) => ({
        ...prevQuantities,
        [ticketId]: value,
    }));
}

export function checkIBuyButtonIsAvailable(ticketQuantities: ITicketQuantities, numberedTickets: Seat[], setCanBuy: React.Dispatch<React.SetStateAction<boolean>>) {
    let result: boolean = false;
    for (let id in ticketQuantities) {
        if (ticketQuantities[id] > 0) {
            result = true;
            break;
        }
    }
    setCanBuy(result || (numberedTickets && numberedTickets.length > 0));
}

export function getTicketListPerQuantity(tickets: ITicket[], ticketQuantities: ITicketQuantities): ITicketInfo[] {

    const ticketList: ITicketInfo[] = [];
    tickets.forEach((ticket) => {
        const ticketId = ticket.id;
        if (ticketQuantities.hasOwnProperty(ticketId)) {
            const quantity = ticketQuantities[ticketId];
            for (let i = 0; i < quantity; i++) {
                ticketList.push(
                    {
                        gift: false,
                        ticket: ticket,
                        email: '',
                        firstName: '',
                        lastName: '',
                        birthDateString: '',
                        phoneNumber: '',
                        postalCode: '',
                        isDatePickerVisible: false,
                        exhausted: false,
                        nominative: false,
                        commission: 0.0,
                        seatid: null
                    }
                );
            }
        }
    });


    return ticketList;
}

export default function copyTitularData(index: number, profile: IUser, ticketList: ITicketInfo[], setTicketList: React.Dispatch<React.SetStateAction<ITicketInfo[]>>) {
    console.log("OnCopy ", profile)

    const newTicketList = [...ticketList];
    const ticket = {...newTicketList[index]};
    const birthDate = new Date(profile.birthdate); // Convertir la cadena de fecha en un objeto Date

    ticket.email = profile.email;
    ticket.firstName = profile.name;
    ticket.birthDateString = birthDate.toLocaleDateString('es-ES'); // Usar el método toLocaleDateString con el objeto Date
    //@ts-ignore
    ticket.birthdate = profile.birthdate ? dayjs(profile.birthdate) : null;
    ;
    ticket.phoneNumber = profile.phone;
    ticket.postalCode = profile.zipcode;

    newTicketList[index] = ticket;
    setTicketList(newTicketList);
}

export function changeTicketInfo(field: string, value: any, index: number, ticketList: ITicketInfo[], setTicketList: React.Dispatch<React.SetStateAction<ITicketInfo[]>>) {
    let ticketInfo = {...ticketList[index], [field]: value};
    let newTicketInfos = [...ticketList];
    newTicketInfos[index] = ticketInfo;

    setTicketList(newTicketInfos);
}

export function openLoginForm(setIsLoginVisible: any, setIsCreateAccountVisible: any) {
    setIsCreateAccountVisible(true);
    setIsLoginVisible(false);
}

export function openCreateForm(setIsLoginVisible: any, setIsCreateAccountVisible: any) {
    setIsLoginVisible(true);
    setIsCreateAccountVisible(false);
}

export function calculateMoneyAmountToBuy(tickets: ITicket[], ticketQuantities: ITicketQuantities, numberedTickes: Seat[]): number {

    let total = 0;
    tickets.forEach((ticket) => {
        const ticketId = ticket.id;
        if (ticketQuantities.hasOwnProperty(ticketId)) {
            const quantity = ticketQuantities[ticketId];
            for (let i = 0; i < quantity; i++) {
                total += ticket.price;
            }
        }
    });

    if (numberedTickes) {
        numberedTickes.forEach(value => total += value.price)
    }

    return total;
}

export function calculateTotalToPayWithTaxes(ticketList: ITicketInfo[], numberedTickets: Seat[], setTotal: React.Dispatch<number>,
                                             setTaxes: React.Dispatch<number>) {
    let total = 0;
    let taxes = 0;
    ticketList.forEach((ticket) => {
        taxes += ticket.ticket.commission;
        total += ticket.ticket.price + ticket.ticket.commission;
    })
    numberedTickets.forEach((ticket) => {
        taxes += ticket.commission;
        total += ticket.price + ticket.commission;
    })
    setTotal(total);
    setTaxes(taxes);
}

export function areNominativeInTicketList(setAreNominative: React.Dispatch<boolean>, ticketList: ITicketInfo[], numberedTickets: Seat[]) {
    setAreNominative(ticketList.some((t) => t.ticket.nominative) || numberedTickets.some((t) => t.nominative));
}

export function buyTickets(ticketList: ITicketInfo[], loggedUser: any, invitedUser: any, callback: any, dispatch: any) {
    generateUuidAndExecuteCallback((uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {
            console.log("Transactionid", uuid)

            const ticketsToBuy = ticketList.map((ticket) => {
                return ({
                    ticketeventid: ticket.ticket.id,
                    gift: ticket.gift,
                    seatid: ticket.seatid,
                    userInfoDTO: {
                        email: ticket.email,
                        name: ticket.firstName + (ticket.lastName ? (" " + ticket.lastName) : ""),
                        birthdate: ticket.birthdate,
                        zipCode: ticket.postalCode
                    }
                })
            })

            const buyInfoDTO = {
                ticketInfoDTOList: ticketsToBuy,
                logguedUser: loggedUser,
                invitedUser: invitedUser
            }

            console.log("buyInfoDTO", buyInfoDTO)

            let url = rootServiceURL() + "completegraph/event/buyTickets/" + uuid;
            let rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
            rc.doPost(buyInfoDTO, (results: any) => {
                console.log("tickets buyed ", results)
                if (rc.isSuccess()) {
                    deleteUuid();
                    callback && callback();
                } else {
                    alert("Error comprando tickets " + rc.getErrors())
                }
            }, false)
        }
    })
}