import React, {useEffect, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import "./styles.css";
import {Seat} from "./model/Seat";
import {Button, Col, Flex, Row} from "antd";
import SeatInfoRow from "./SeatInfoRow";
import {rootServiceURL} from "../../Controller";
import {ReactSVGPanZoom, UncontrolledReactSVGPanZoom, TOOL_NONE} from "react-svg-pan-zoom";
import {ReactSvgPanZoomLoader, SvgLoaderSelectElement} from "react-svg-pan-zoom-loader";
import RestClient from "../../Controller/rest/RestClient";
import {useDispatch} from "react-redux";

export type SeatMapProps = {
    eventdate?: number;
    onSelectSeat?: (seat: Seat) => void
    onRemoveSeat?: (seat: Seat) => void
    onSeatReservationChanged?: (seats: Seat[]) => void
};

const RESERVATON_URL = rootServiceURL() + "minimalgraph/reservation/getZones/";
//@ts-nocheck
export function SeatReservation(props: SeatMapProps) {
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [selectedSector, setSelectedSector] = useState<string>()
    const [selectedSeats, setSelectedSeats] = useState<Seat[]>([]);
    const svgRef = useRef<ReactSVG | null>(null);
    const svgElementRef = useRef<SVGSVGElement | null>(null)
    const [tool, setTool] = useState(TOOL_NONE)
    const [value, setValue] = useState({});

    const [svgXML, setSvgXML] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const url = rootServiceURL() + ("minimalgraph/reservation/svgXML/31/SP4")
        let rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
        rc.doGet((result: any) => {
            setSvgXML(result);
        })
        return () => disableListeners();
    }, []);

    useEffect(() => {
        if(props.eventdate){
            setUrl(RESERVATON_URL + props.eventdate)
        }
    }, [props.eventdate]);

    useEffect(() => {
        if(selectedSector){
            loadSector(selectedSector)
        } else {
            setUrl(RESERVATON_URL + props.eventdate)
        }
    }, [selectedSector]);

    useEffect(() => {
        props.onSeatReservationChanged && props.onSeatReservationChanged(selectedSeats)
    }, [selectedSeats])

    function handleSeatClick(event: Event) {
        toggleSeatSelection(event.currentTarget as Element);
    }
    function handleSectorClick(event: Event) {
        let element = event.currentTarget as Element;
        //@ts-ignore
        setSelectedSector(element.getAttribute("data-code"));
    }

    function getTotalRow() : Seat {
        return new Seat({
            seat: "TOTAL",
            sector: selectedSeats.length + " asientos seleccionados",
            price: selectedSeats.reduce((p, c, i) => p + c.price, 0.0)
        })
    }
    function enableListeners() {
        let sectors =  Array.from(document.querySelectorAll(".sector")).filter(sector => {
           if(sector){
               let classAttr = sector.getAttribute("data-code");
               return classAttr && classAttr.length > 0
           }
           return false
        });
        sectors.forEach((sector) => {
            sector.addEventListener("click", handleSectorClick);
        });
        let seats = Array.from(document.querySelectorAll(".seat")).filter(seat => {
            if(seat){
                let classAttr = seat.getAttribute("class");
                return classAttr && ! classAttr.includes("busy") && ! classAttr.includes("disabled")
            }
            return false
        });
        seats.forEach((seat) => {
            seat.addEventListener("click", handleSeatClick);
        });
    }

    function disableListeners() {
        let elements = document.querySelectorAll("[id^=sector]");
        elements.forEach((anElement) => {
            anElement.removeEventListener("click", handleSectorClick);
        });
        elements = document.querySelectorAll(".seat");
        elements.forEach((anElement) => {
            anElement.removeEventListener("click", handleSeatClick);
        });
    }

    function svgLoaded(svg: SVGElement) {
        disableListeners();
        enableListeners();
    }

    function loadSector(id: string | null) {
        setUrl(rootServiceURL() + ("minimalgraph/reservation/svg/" +props.eventdate+"/"+ id));
    }

    const toggleSeat = (seat: Seat) => {
        const seatIndex = selectedSeats.findIndex((s) => s.id === seat.id);

        if (seatIndex !== -1) {
            const updatedSeats = [...selectedSeats];
            updatedSeats.splice(seatIndex, 1);
            props.onRemoveSeat && props.onRemoveSeat(selectedSeats[seatIndex])
            setSelectedSeats(updatedSeats);
        } else {
            props.onSelectSeat && props.onSelectSeat(seat)
            setSelectedSeats([...selectedSeats, seat]);
        }
    }

    const applyClassesToSeats = (svgElement: SVGSVGElement | null) => {
        if (svgElement) {
            const groups = svgElement.querySelectorAll(".seat");
            groups.forEach((group) => {
                const seatId = group.getAttribute("data-id");
                if (seatId) {
                    const isSelected = selectedSeats.some((seat) => seat.id === parseInt(seatId, 10));
                    group.classList.toggle("selected", isSelected);
                }
            });
        }
    };

    function toggleSeatSelection(element: Element) {
        let ticketEventId = element.getAttribute("data-ticketeventid");
        let nominativeElement =  element.getAttribute("data-nominative");

        let seat = new Seat({
            id: parseInt(element.getAttribute("data-id") as string),
            seat: element.getAttribute("data-seat"),
            position: parseInt(element.getAttribute("data-position") as string),
            row: element.getAttribute("data-row"),
            price: parseFloat(element.getAttribute("data-price") as string),
            entrance: element.getAttribute("data-entrance"),
            sector: element.getAttribute("data-sector"),
            ticketeventid: ticketEventId ? parseInt(ticketEventId as string) : null,
            commission: parseFloat(element.getAttribute("data-commission") as string),
            handicapped: false,
            nominative: nominativeElement ? (nominativeElement as string === "true") : false
        });
        element.classList.toggle("selected");
        toggleSeat(seat);
    }


    const minitiatureProps = {
        miniaturePosition: "left",
        miniatureBackground: "#fff",
        miniatureWidth: 100,
        miniatureHeight: 80
    }

    const toolbarProps = {
        toolbarPosition: "right"
    }

    // @ts-ignore
    return (
        <>
            <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {selectedSector && <Flex vertical gap="small" style={{ width: '100%', marginBottom: 20 }}>
                        <button className={'back-button'} onClick={() => setSelectedSector(undefined)}>VOLVER AL PLANO</button>
                    </Flex>}
                    {props.eventdate && url && svgXML &&
                        //@ts-ignore
                        // <ReactSVGPanZoom
                        //     width={300} height={300}
                        //     toolbarProps={toolbarProps} miniatureProps={minitiatureProps}  tool={tool} value={value} onChangeTool={setTool} onChangeValue={setValue}
                        //     // onClick={event => console.log(event.x, event.y, event.originalEvent)}
                        // >
                        //     {svgXML}
                        // </ReactSVGPanZoom>
                        //@ts-ignore

                        // <ReactSVG
                        //     height={"100%"}
                        //     width={"100%"}
                        //     src={url!}
                        //     beforeInjection={(svg) => {
                        //         svgElementRef.current = svg
                        //         if (svgElementRef.current) {
                        //             applyClassesToSeats(svgElementRef.current)
                        //         }
                        //     }}
                        //     afterInjection={svgLoaded}
                        // />

                        <ReactSvgPanZoomLoader
                            //@ts-ignore
                            svgXML={svgXML}
                            proxy={
                                <>
                                    <SvgLoaderSelectElement
                                        selector="#seat48999"
                                        onClick={(e:any) => {
                                            console.log("Click en asiento.. ",e)
                                            alert("seat48999")   ;
                                        }}
                                    />
                                </>
                            }
                            render={content => {
                                console.log("Content ",content)
                                return(
                                    //@ts-ignore
                                    <ReactSVGPanZoom

                                        width={500} height={500}
                                        toolbarProps={toolbarProps}
                                        miniatureProps={minitiatureProps}
                                        tool={tool}
                                        value={value}
                                        onChangeTool={setTool}
                                        onChangeValue={setValue}
                                        onClick={event => {
                                            console.log("event" ,event, event.x, event.y, event.originalEvent)

                                            // @ts-ignore
                                            let oldSVG = {...svgXML};
                                            const groups = event.SVGViewer.querySelectorAll(".seat");
                                            groups.forEach((group: { getAttribute: (arg0: string) => any; classList: { toggle: (arg0: string, arg1: boolean) => void; }; }) => {
                                                const seatId = group.getAttribute("data-id");
                                                if (seatId) {
                                                    const isSelected = selectedSeats.some((seat) => seat.id === parseInt(seatId, 10));
                                                    group.classList.toggle("selected", isSelected);
                                                }
                                            });
                                            svgLoaded(event.SVGViewer)
                                        }
                                       }
                                    >
                                        <svg width={500} height={500}>
                                            {content}
                                        </svg>

                                    </ReactSVGPanZoom>
                                )
                            }}
                        />
                    }
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <table className={"table"}>
                        <SeatInfoRow isTotal={true} seat={getTotalRow()} />
                        {selectedSeats.map(seat => <SeatInfoRow onDelete={toggleSeat} seat={seat} />)}
                    </table>
                </Col>
            </Row>
        </>
    );
}
