import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Header from "../../components/header/Header";
import {Card, Col, Row} from "antd";
import styles from './Events.module.css';
import React, { useEffect, useState} from 'react';
import {getEventAndCategories} from "../../solutions/controllers/EventController";
import {ICategory} from "../../solutions/interfaces/ICategory";
import {IEvent} from "../../solutions/interfaces/IEvent";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../Controller/redux/auth/actions";
import {formatDateToString} from "../../solutions/Utils";

const CarrouselEvents = ({ events }: { events: IEvent[] })=>{
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 9
        },
        desktop: {
            breakpoint: { max: 3000, min: 1555 },
            items: 7
        },
        tablet: {
            breakpoint: { max: 1555, min: 1100 },
            items: 5
        },
        midTable: {
            breakpoint: { max: 1100, min: 943 },
            items: 4
        },
        smallTablet: {
            breakpoint: { max: 943, min: 680 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 680, min: 480 },
            items: 2
        },
        smallMobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel centerMode={true} className={styles.carrousel} responsive={responsive}>
            {events.map((event, index) => (
                <Link to={"/eventDetails/"+event.id} key={event.id} style={{ textDecoration: 'none' }}>
                    <Card
                        key={event.id}
                        hoverable
                        className={styles.eventCard}
                        bodyStyle={{padding:0}}
                    >
                        <img width={180} height={210} className={styles.eventImage}  alt="example" src={event.image} />

                        <Row>
                            <Col span={24}>
                                <p className={styles.eventTitle}>{event.title}</p>
                            </Col>
                        </Row>

                        <Row style={{marginLeft:10,marginRight:10,marginTop:0}}>
                            <span className={styles.eventDate}>{formatDateToString(event.fromDate)}</span>
                            <span className={styles.eventPrice}>DESDE {event.price}€</span>
                        </Row>
                    </Card>
                </Link>
            ))}
        </Carousel>
    );

}
// @ts-nocheck
export default function Events(){
    const [categorySelected, setCategorySelected] = useState('Todas');
    const [originalEventData, setOriginalEventData] = useState<IEvent[]>([]);
    const [filterEventData, setFilterEventData] = useState<IEvent[]>(originalEventData);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [news, setNews] = useState('');
    const dispatch = useDispatch();
    //@ts-ignore
    const auth = useSelector(state => state.auth);

    /*const showAllEvents = () => {
        filterEvents('Todas', 0, 0,setFilterEventData, filterEventData, originalEventData);
        setCategorySelected('Todas')
    };*/

    /*useEffect(() =>{
        showAllEvents()

    }, [originalEventData])*/

    useEffect(() => {
        getEventAndCategories(setOriginalEventData, setCategories, dispatch);
    }, []);
    useEffect(()=>{
        if (originalEventData){
        }
    },[originalEventData])
    useEffect(() =>{
        if (originalEventData && originalEventData.length>0){
            setFilterEventData(originalEventData);
        }

    }, [originalEventData])

    useEffect(() =>{
        if (auth){
            console.log("Auth obtenida de redux ",auth)
        }

    }, [auth])

    // @ts-ignore
    /*function handleCategoryPress(category){
        setCategorySelected(category)
        filterEvents(category, 0, 0,setFilterEventData, filterEventData, originalEventData);
    }*/
    return (
        <div className={styles.eventBackground}>
            <Row style={{paddingBottom:30}}></Row>
            <Header></Header>
            <Row justify={'center'}  style={{ height: '100%', paddingTop: 20}}>
                <h1 className={styles.categoryEventTitle}>EVENTOS DESTACADOS</h1>
            </Row>
            <div style={{marginLeft:20, marginRight:20, paddingBottom: 20}}>
                <CarrouselEvents events={originalEventData}></CarrouselEvents>
            </div>
            {categories
                ? categories.map((category) => (
                    filterEventData.filter((e) => e.category.eventtypeid === category.eventtypeid).length > 0 ? (
                        <div style={{marginTop:30, paddingBottom: 20}}>
                            <Row justify={'center'} >
                                    <h1 className={styles.categoryEventTitle}>{category.eventtype}</h1>
                            </Row>
                            <div style={{marginLeft:20, marginRight:20}}>

                            <CarrouselEvents events={filterEventData.filter(e=>e.category.eventtypeid===category.eventtypeid)}></CarrouselEvents>
                            </div>
                        </div>
                    ) : null
                ))
                : null}

        </div>
    );

}