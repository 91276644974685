import {Card, Col, Row, Space} from "antd";
import React, { useEffect, useState} from 'react';
import styles from './LegalAdvice.module.css';
import Header from "../../components/header/Header";


// @ts-nocheck
export default function LegalAdvice(){

    return (
        <div style={{background: "black"}}>
            <Row justify={"center"} style={{paddingTop: 30}}>
                <Col span={20} offset={2}>
                    <Header></Header>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24} style={{display:"flex", alignItems: "center", color: "white", fontSize: 40, flexDirection: "column"}}>
                    <p>Aviso Legal</p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <p className={styles.section}>
                        I. Datos identificativos
                    </p>
                    <p className={styles.content}>
                        Esta página web es propiedad de la entidad "NFT MY TICKET, S.L." (en adelante, la "Empresa") cuyos datos son los siguientes:



                        Domicilio social: Plaza Isabel II, 5, 4ºC, 28013, Madrid.



                        N.I.F: B09863408



                        Objeto social: Intermediación y venta online de entradas de todo tipo de espectáculos (excepto taurinos).



                        Registro mercantil: [*]



                        E-mail: info@nftmyticket.com
                    </p>

                    <p className={styles.section}>
                        II. Aceptación del usuario
                    </p>
                    <p className={styles.content}>
                        El acceso y/o el uso del sitio web www.tixxlab.com (en adelante "página web") le atribuye la condición de usuario y, desde dicho acceso y/o uso, acepta las presentes Condiciones de uso (en adelante "Condiciones") y, en su caso, los cambios efectuados sobre las mismas, por lo que debe leerlas detenidamente antes de navegar y usar este sitio web.



                        Las presentes Condiciones regulan los términos de acceso de la página web, incluyendo los contenidos y los servicios puestos a disposición de los usuarios a través de esta página web.
                    </p>

                    <p className={styles.section}>
                        III. Responsabilidad
                    </p>
                    <p className={styles.content}>
                        El usuario se obliga a utilizar esta página web conforme a lo establecido en las presentes Condiciones, en la Política de privacidad, en el Aviso legal y en la legislación vigente, respondiendo frente a la Empresa y/o frente a terceros, de cualquier daño o perjuicio causado por incumplimiento de las mismas.



                        El Usuario se compromete a hacer un uso adecuado y lícito de la página web y de los servicios y contenidos en la misma.



                        En tal sentido, con carácter enunciativo, pero no limitativo, los usuarios no deberán comunicar datos a través de la página web que no sean verdaderos, exactos, completos y/o actualizados, ni datos personales de terceros sin su consentimiento previo y expreso, ni utilizar la página web con fines fraudulentos o ilícitos.



                        En caso de incumplimiento de las presentes Condiciones, la Empresa se reserva el derecho de denegar al usuario el acceso al servicio ofrecido.



                        Esta página web ha sido elaborada con información procedente de fuentes tanto internas como externas, no garantizando la Empresa, por tanto, la disponibilidad, la veracidad, la calidad, la exactitud o actualidad de la información que contiene, por lo que la Empresa declina cualquier tipo de responsabilidad por los daños y perjuicios que puedan causarse por el uso de los servicios aquí contenidos.



                        La Empresa no se hace responsable de los daños y perjuicios que pudieran ocasionar, a título enunciativo: causas de fuerza mayor o cualesquiera otras causas ajenas a la misma; falta de disponibilidad de la página web; errores u omisiones cometidos por el usuario en la introducción de sus datos; errores o deficiencias de seguridad producidos por parte del usuario; utilización de un navegador con una versión no actualizada o insegura; daños y perjuicios que pudiera causar la información que se transmite o comunique en sus sitios web o en sus comunicaciones electrónicas.



                        Queda expresamente prohibida la creación de enlaces de hipertexto (links) a cualquier elemento integrante de esta web sin la autorización de la empresa.
                    </p>

                    <p className={styles.section}>
                        IV. Propiedad intelectual e industrial
                    </p>
                    <p className={styles.content}>
                        Todos los contenidos (marcas, logos, imágenes, documentación, códigos fuentes, programas informáticos, diseño gráfico o cualquier otro elemento susceptible de protección por la legislación de propiedad intelectual o industrial) incorporados o accesibles desde esta web, corresponden exclusivamente a la Empresa, la cual se reserva expresamente todos los derechos sobre los mismos.



                        Queda prohibido la reproducción, distribución, comunicación pública, transformación y, en general, cualquier otra forma de explotación de todo o parte de los contenidos de este sitio web. Estos actos de explotación solo podrán ser realizados si media autorización expresa del titular y siempre que se haga referencia explícita al titular de los derechos de propiedad intelectual e industrial.



                        La Empresa no concede ninguna licencia o autorización de uso sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con la página web, o los servicios y contenidos de la misma.
                    </p>

                    <p className={styles.section}>
                        V. Privacidad, protección de datos y comunicaciones comerciales
                    </p>
                    <p className={styles.content}>
                        Los datos del usuario que se recaben a través de la página web se usarán y se tratarán conforme a lo dispuesto en nuestra Política de privacidad. Asimismo, en dicha política se establecerá el procedimiento para darse de baja del envío de comunicaciones comerciales de nuestra entidad.
                    </p>

                    <p className={styles.section}>
                        VI. Notificaciones
                    </p>
                    <p className={styles.content}>
                        Cualquier comunicación, solicitud de información, petición, queja o sugerencia del usuario deberá realizarse por escrito a través de la siguiente dirección info@nftmyticket.com
                    </p>

                    <p className={styles.section}>
                        VII. Modificaciones
                    </p>
                    <p className={styles.content}>
                        La Empresa se reserva la facultad de modificar en cualquier momento las presentes Condiciones para adaptarlas a las novedades legislativas y jurisprudenciales y para mejorar el servicio de los usuarios. Por ello, el usuario se obliga a consultar las presentes Condiciones cuando acceda y/o utilice la misma.
                    </p>

                    <p className={styles.section}>
                        VIII. Suspensión del servicio
                    </p>
                    <p className={styles.content}>
                        La Empresa podrá suspender temporalmente y, sin previo aviso, el acceso a la página web para realizar tareas de mantenimiento, reparación, actualización o mejora de la misma.
                    </p>

                    <p className={styles.section}>
                        IX. Legislación y jurisdicción competente
                    </p>
                    <p className={styles.content}>
                        Las presentes Condiciones de uso se rigen y se interpretan de acuerdo con la ley española. Para cualquier reclamación serán competentes los juzgados y tribunales de Madrid.
                    </p>
                </Col>
            </Row>
        </div>
    )
}