
export interface SeatDTO {
    id?: number | null
    row?: string | null
    seat?: string | null
    position?: number | null
    handicapped?: boolean | null
    entrance?: string | null
    sector?: string | null
    price?: number | null
    commission?: number | null
    ticketeventid?: number | null
    nominative?: boolean | null
}

export class Seat {
    private readonly _id: number
    private readonly _row: string
    private readonly _position: number
    private readonly _seat: string
    private readonly _handicapped: boolean
    private readonly _price: number
    private readonly _entrance: string
    private readonly _sector: string
    private readonly _commission: number
    private readonly _ticketeventid: number
    private readonly _nominative: boolean


    constructor(p: SeatDTO) {
        this._id = p.id || 0
        this._seat = p.seat || ""
        this._position = p.position || 0
        this._row = p.row || ""
        this._price = p.price || 0
        this._entrance = p.entrance || ""
        this._sector = p.sector || ""
        this._handicapped = p.handicapped || false
        this._commission = p.commission || 0
        this._ticketeventid= p.ticketeventid || 0
        this._nominative = p.nominative || false
    }


    get id(): number {
        return this._id;
    }

    get row(): string {
        return this._row;
    }

    get position(): number {
        return this._position;
    }

    get seat(): string {
        return this._seat;
    }

    get handicapped(): boolean {
        return this._handicapped;
    }

    get price(): number {
        return this._price;
    }

    get entrance(): string {
        return this._entrance;
    }

    get sector(): string {
        return this._sector;
    }

    get commission(): number {
        return this._ticketeventid;
    }

    get ticketeventid(): number {
        return this._ticketeventid;
    }

    get nominative(): boolean {
        return this._nominative;
    }

    get description() :string{
        return ("Asiento "+ this.seat+" "+this.sector+", Fila "+this.row);
    }
}
