// export const serverName = localStorage.getItem("preferences.server") || window.location.hostname + ":8080";
//export const serverName = "192.168.100.114:8080";
export const serverName = "tixxlab-dev.iten.es:8080";
//export const serverName = "192.168.31.67:8080";

export const server = "http://" + serverName + "/";

export const CONFIG = {
    firstScreen: "Dashboard",
    restService: {
        restLoginUrl: server + "phocus-kpi/fullgraph/phocusservice/login",
        restRegistrationUrl: server + "phocus-kpi/fullgraph/phocusservice/register",
        restServiceRoot: server + "PhocusTXL/",
        kpiUrl: server + "phocus-kpi/",
        uiService: "minimalgraph/ui/",
        dataService: "minimalgraph/rest/",
        completeGraphDataService: "completegraph/rest/",
        fullGraphDataService: "fullgraph/rest/",
        uploadFileService: "completegraph/fileservice/",
        reportService: "completegraph/reportingService/",
        ws: "ws://" + serverName + "/PhocusTXL/phocus",
        wsKpi: "ws://" + serverName + "/phocus-kpi/phocus"

    }, firebaseSenderId: "313056414449"
}

export const CUSTOM_SERVICES_FOR_ENTITIES = {}

export const CUSTOM_SERVICES_FOR_REPORTS = {}

export const CUSTOM_FIELD_RENDERERS = {}
