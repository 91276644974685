import {ICategory} from "../interfaces/ICategory";
import {IEvent} from "../interfaces/IEvent";
import React, {Dispatch, SetStateAction} from "react";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {AnyAction} from "redux";

// @ts-nocheck
export function getEventAndCategories(setOriginalEventData: React.Dispatch<React.SetStateAction<IEvent[]>>,
                                      setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>, dispatch: Dispatch<AnyAction>) {
    // @ts-ignore

    let url = rootServiceURL() + "completegraph/rest/eventtype";

    let rc = new RestClient(dispatch).url(url).token("thisIsAValidTokenButDontUseIt");
    rc.doGet((results: ICategory[])  => {
        if(results){
            setCategories(results)
        }
        else{
            console.error("Error cargando tipo de eventos", rc.isSuccess(), rc.getErrors())
            setCategories([])
        }
    })

    url = rootServiceURL() + "completegraph/event/getResumedEvents";
    rc = new RestClient(dispatch).url(url).token("tokenUnnecessary");
    // @ts-ignore
    rc.doGet(r => {
        if (r) {
            const recordTransformed = r.map( (event: any) => {
                return({
                    id: event.eventId,
                    title: event.title,
                    price: event.minPrice,
                    image: "data:image/png;base64,"+ event.image,
                    category: event.type,
                    fromDate: event.date,
                    town: event.town,
                    finished: false,
                })
            });
            //console.log("recordTransformed", recordTransformed)
            setOriginalEventData(recordTransformed)
        } else {
            setOriginalEventData([])
            console.error("Error cargando eventos", rc.getErrors())
        }
    });
}

/*export function filterEvents (category:string, minPrice:number, maxPrice:number, setFilterEventData:Dispatch<SetStateAction<IEvent[]>>, filterEventData:IEvent[], originalEvents:IEvent[])  {

    setFilterEventData(category==='Todas'?originalEvents:originalEvents
        .filter(e=>e.category.category===category));
    if (minPrice>0 && maxPrice>0){
        setFilterEventData(filterEventData.filter(e=>e.price>=minPrice && e.price<=maxPrice));
    }
}*/
