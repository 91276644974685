import {Card, Col, Row, Space} from "antd";
import React, { useEffect, useState} from 'react';
import styles from './PrivacyPolicy.module.css';
import Header from "../../components/header/Header";


// @ts-nocheck
export default function PrivacyPolicy(){

    return (
        <div style={{background: "black"}}>
            <Row justify={"center"} style={{paddingTop: 30}}>
                <Col span={20} offset={2}>
                    <Header></Header>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24} style={{display:"flex", alignItems: "center", color: "white", fontSize: 40, flexDirection: "column"}}>
                    <p>Política de privacidad</p>
                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2}>
                    <p className={styles.section}>

                    </p>
                    <p className={styles.content}>
                        Todo aquel que navegue en el sitio web www.tixxlab.com (en lo sucesivo, la "web") será considerado usuario.



                        La entidad NFT MY TICKET, S.L. se reserva el derecho de modificar la presente Política de Privacidad, así como las Condiciones de Uso de la web, y la Política de Cookies en cualquier momento y cuando lo considere oportuno y/o para adaptarlas a la normativa vigente. No obstante, se recomienda al usuario o a cualquier interesado que revise periódicamente estos textos legales para estar informado sobre el tratamiento de sus datos por la Empresa y sobre las normas de uso de la web.
                    </p>

                    <p className={styles.section}>
                        ¿Quién es el responsable del tratamiento de sus datos personales?
                    </p>
                    <p className={styles.content}>
                        Domicilio social: Plaza Isabel II, 5, 4ºC, 28013, Madrid.



                        N.I.F: B09863408



                        Objeto social: Intermediación y venta online de entradas de todo tipo de espectáculos (excepto taurinos)



                        E-mail: info@nftmyticket.com
                    </p>

                    <p className={styles.section}>
                        ¿Qué tipo de datos personales tratamos y de dónde proceden?
                    </p>
                    <p className={styles.content}>
                        Para navegar en la web no es necesario que el usuario revele sus datos personales.



                        No obstante, para contratar nuestros servicios, será necesario introducir los datos identificativos solicitados. También será necesaria la aceptación de la Política de Privacidad y de las Condiciones de Uso de la web. Por ello, se recomienda al usuario que, con carácter previo, lea detenidamente los referidos textos legales.



                        De no proporcionarse los datos personales solicitados y de no aceptar los referidos textos legales, no se podrá ejecutar la petición del usuario.



                        Recabamos datos a través de las cookies o dispositivos de almacenamiento y recuperación de datos sobre navegación y uso que haces de nuestra web.



                        En tal sentido, hemos adoptado las medidas técnicas y organizativas para mantener la seguridad de sus datos, al objeto de evitar, en la medida de lo posible y según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no autorizado.
                    </p>

                    <p className={styles.section}>
                        ¿Con qué finalidad tratamos sus datos?
                    </p>
                    <p className={styles.content}>
                        Los datos recabados a través del sitio web serán tratados por la Empresa para la prestación del servicio contratado por el usuario. Esta finalidad incluye: (i) gestión de pagos, (ii) informar sobre posibles modificaciones de los eventos ofertados en la web, (iii) fines de facturación y para poner a disposición de los usuarios los justificantes de compra, (iv) el tratamiento de los datos para contactar con el usuario en relación con las funcionalidades, productos o servicios contratados, incluyendo el envío de publicidad, promociones y encuestas sobre los productos o servicios prestados.



                        Asimismo, los datos personales podrán tratarse con la finalidad de atender a las solicitudes, dudas o peticiones que realicen los usuarios a través de los canales indicados en la web.



                        La Empresa no llevará a cabo decisiones automatizadas sobre los datos personales ni elaboración de perfiles.
                    </p>

                    <p className={styles.section}>
                        ¿Cuál es la legitimación para el tratamiento de sus datos?

                    </p>
                    <p className={styles.content}>
                        La Empresa, para la gestión de la contratación de servicios, pago, facturación y envíos correspondientes está legitimada por la propia ejecución del contrato.



                        En caso del tratamiento de los datos recogidos a través del formulario de contacto, la empresa se encuentra legitimada por el consentimiento previo del usuario.
                    </p>

                    <p className={styles.section}>
                        ¿Por cuánto tiempo conservaremos sus datos?
                    </p>
                    <p className={styles.content}>
                        Los datos personales proporcionados se conservarán durante el tiempo estrictamente necesario para las finalidades del tratamiento para cuyo uso hayan sido proporcionados y como máximo el tiempo que la normativa aplicable prevé a los efectos, siempre que el usuario o interesado no haya revocado su consentimiento.



                        En cualquier caso, cuando el uso de los datos proporcionados no sea necesario o se haya revocado el consentimiento, los datos permanecerán bloqueados, quedando a disposición de las autoridades competentes durante a los efectos de atender posibles responsabilidades legales. Transcurrido ese plazo, se procederá a la eliminación de los datos.
                    </p>

                    <p className={styles.section}>
                        ¿A qué destinatarios se comunicarán sus datos?
                    </p>
                    <p className={styles.content}>
                        La Empresa no realizará cesiones de datos salvo aquellas comunicaciones de datos que sean necesarias por obligación legal (por ejemplo: administraciones públicas) o para la prestación del servicio o petición solicitada.



                        Sin perjuicio de lo anterior, la Empresa utiliza el servicio de terceros que podrían tener acceso a sus datos solo en su condición de Encargado de Tratamiento, y a los únicos fines de prestar el servicio solicitado por la Empresa. En este sentido, solo accederán a sus datos bajo las instrucciones de la Empresa y de acuerdo a las condiciones contractuales impuestas por ella siempre dirigidas a proteger sus datos personales.



                        No se realizarán transferencias internacionales de los datos personales.
                    </p>

                    <p className={styles.section}>
                        ¿Cuáles son sus derechos cuando nos facilita sus datos?
                    </p>
                    <p className={styles.content}>

                    </p>

                    <p className={styles.section}>
                        ¿Cuáles son sus derechos cuando nos facilita sus datos?
                    </p>
                    <p className={styles.content}>
                        Cualquier interesado puede ejercer sus derechos de acceso, con el fin de que la entidad la Empresa le confirme si está tratando sus datos personales; de rectificación de los datos inexactos que le conciernan; de supresión, cuando ya no sean necesarios para los fines para los que fueron recogidos o de retirada del consentimiento, entre otros supuestos. En tal caso, dejaremos de tratar los datos, salvo por motivos de interés público, para el cumplimiento de una obligación legal o para la formulación, ejercicio y defensa de reclamaciones.



                        Asimismo, el interesado tiene derecho a la limitación del tratamiento en ciertos casos como, por ejemplo, cuando el interesado impugne la exactitud de sus datos; a la portabilidad de los datos personales cuando el tratamiento esté basado en el consentimiento, en un contrato o se efectúe por medios automatizados; de oposición y a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de datos.



                        El interesado también podrá revocar su consentimiento prestado para el tratamiento de sus datos con las finalidades indicadas.



                        El ejercicio de estos derechos es personalísimo. Por ello, el interesado podrá ejercer sus derechos ante la Empresa, a través del correo electrónico info@nftmyticket.com, mediante comunicación firmada por el mismo y adjuntando copia de su documento de identidad.



                        El interesado podrá presentar una reclamación ante la Agencia Española de Protección de Datos, u autoridad competente, si entiende que la Empresa no ha atendido debidamente sus derechos o está vulnerando sus derechos de protección de datos.
                    </p>

                    <p className={styles.section}>
                        ¿Cómo puedes darte de baja en nuestras comunicaciones comerciales?
                    </p>
                    <p className={styles.content}>
                        El usuario o interesado podrá revocar el consentimiento prestado y darse de baja, en cualquier momento, enviando un correo electrónico con el texto "Baja" a través del correo electrónico info@nftmyticket.com o siguiendo las instrucciones que aparecen en el pie del correo electrónico recibido con nuestras comunicaciones comerciales.
                    </p>

                    <p className={styles.section}>
                        Aceptación y Consentimiento
                    </p>
                    <p className={styles.content}>
                        El usuario declara haber sido informado de las condiciones sobre protección de datos personales, aceptando y consintiendo el tratamiento de los mismos por parte de la Empresa, en la forma y para las finalidades indicadas en la presente Política de Privacidad.
                    </p>

                    <p className={styles.section}>
                        Cómo eliminar tu Cuenta y qué sucede al hacerlo:
                    </p>
                    <p className={styles.content}>
                        Tienes total libertad para borrar tu Cuenta de Tixxlab, sea cual sea el motivo. La mayoría de la información se eliminará de la Cuenta de forma inmediata, aunque puede llevar un tiempo borrar todo de nuestros archivos y copias de seguridad.



                        Si quieres eliminar tu Cuenta, puedes hacerlo enviando un mail con tus datos personales al correo electrónico support@nftmyticket.com. Recuerda que, al hacerlo, es posible que no borres completamente el contenido de nuestro sistema, ya que este puede seguir almacenado en la memoria caché, en copias de seguridad o en otro tipo de copias o menciones durante un tiempo.
                    </p>
                </Col>
            </Row>
        </div>
    )
}