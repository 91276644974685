import {Card, Col, Row, Space} from "antd";
import React, { useEffect, useState} from 'react';
import styles from './CookiesPolicy.module.css';
import Header from "../../components/header/Header";


// @ts-nocheck
export default function CookiesPolicy(){

    return (
        <div style={{background: "black", height: '700px'}}>
            <Row justify={"center"} style={{paddingTop: 30}}>
                <Col span={20} offset={2}>
                    <Header></Header>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24} style={{display:"flex", alignItems: "center", color: "white", fontSize: 40, flexDirection: "column"}}>
                    <p>Política de cookies</p>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col span={24} style={{display:"flex", alignItems: "center", color: "white", fontSize: 40, flexDirection: "column"}}>
                    <p>Actualmente no utilizamos ninguna cookie.</p>
                </Col>
            </Row>

        </div>
    )
}