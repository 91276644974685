import React from 'react';
import './App.css';
// @ts-ignore
import {
    BrowserRouter as Router,
    createBrowserRouter,
    createRoutesFromElements,
    Route, RouterProvider,
    Routes
} from 'react-router-dom';
import Events from "./pages/events/Events";
import EventDetails from "./pages/eventDetails/EventDetails";
import Collection from "./pages/collection/Collection";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store";
// import {store, persistor} from "/";
import LoginRegisterUser from "./pages/loginRegisterUser/LoginRegisterUser";
import Buy from "./pages/buy/Buy";
import {SeatReservation} from "./components/seatReservation/SeatReservation";
import BuyTest from "./pages/buy/BuyTest";
import TermAndConditions from "./pages/termAndConditions/TermAndConditions";
import LegalAdvice from "./pages/legalAdvice/LegalAdvice";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/cookiesPolicy/CookiesPolicy";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/"  element={<Events/>}/>
            <Route path="/eventDetails/:eventId" element={<EventDetails/>} />
            <Route path="/collection" element={<Collection/>} />
            <Route path="/loginRegister" element={<LoginRegisterUser/>} />
            <Route path="/buy" element={<Buy/>} />
            <Route path="/buyTest" element={<BuyTest/>} />
            <Route path="/termAndConditions" element={<TermAndConditions/>} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
            <Route path="/legalAdvice" element={<LegalAdvice/>} />
            <Route path="/cookiesPolicy" element={<CookiesPolicy/>} />
            <Route path="/reservation" element={<SeatReservation />} />
        </>
    )
);

function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <RouterProvider router={router} />
            </PersistGate>
        </Provider>
    );
}

export default App;
