import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select} from "antd";
import styles from "./RegisterForm.module.css";
import React, {useEffect, useState} from "react";
import locale from 'antd/es/date-picker/locale/es_ES';
const dateFormat = 'DD/MM/YYYY';

type FieldType = {
    firstName?: string;
    birthdate?: string;
    email?: string;
    phoneNumber?: number;
    postalCode?: number;
    password?:string;
    repeatPassword?:string;
};
export default function RegisterForm({ form, isBuy}: { form: FormInstance,isBuy:boolean }) {
    const [selectState, setSelectState] = useState('man');

    useEffect(()=>{
        const datePickerInput = document.querySelector('#registerForm_birthdate');
        if(datePickerInput){
            // @ts-ignore
            datePickerInput.style.color= 'white';
        }
    })
    return (
        <Form
            form={form}
            name="registerForm"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width:'100%' }}
            initialValues={{ remember: true }}
            autoComplete="off"
        >
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                        name="email"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Please input your email!', type:'email' }]}
                    >
                        <Input className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>NOMBRE Y APELLIDOS</span>}
                        name="firstName"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>FECHA DE NACIMIENTO</span>}
                        name="birthdate"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Please input your birthdate!' }]}
                    >
                        <DatePicker locale={locale} format={dateFormat} placeholder={''} className={styles.datepicker}  style={{width:'100%', color:'white'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>TELÉFONO</span>}
                        name="phoneNumber"
                        style={{ width: '100%', color:'white' }}
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%',color:'white'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>CÓDIGO POSTAL</span>}
                        name="postalCode"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Please input your postal code!' }]}
                    >
                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            {!isBuy?(
                    <>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>CONTRASEÑA</span>}
                                    name="password"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>REPITA CONTRASEÑA</span>}
                                    name="repeatPassword"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Please input your repeated password!' }]}
                                >
                                    <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
            ):null}

        </Form>
    );
}