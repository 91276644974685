import React from "react";
import {IUser} from "../interfaces/IUser";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";

const profile = {
    firstName:'Juan Antonio',
    lastName:'Perez Gómez',
    email:'juan@gmail.com',
    birthDate:new Date(),
    phoneNumber:'678987653',
    zipcode:'43213',
    profilePhoto: 'https://weremote.net/wp-content/uploads/2022/08/mujer-sonriente-apunta-arriba.jpg',
    genre:'male',
};
export function getProfile(auth: any, setProfile:React.Dispatch<React.SetStateAction<IUser | undefined>>, dispatch: any) {
    let url = rootServiceURL() + "completegraph/users/getUserFromToken";

    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doGet((results: any[]) => {
        if(results){
            //@ts-ignore
            setProfile(results)
        }
        else{
            //@ts-ignore
            setProfile(null)
        }
    })
}


export function registerUser(user: any, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/registerUser";

    let rc = new RestClient(dispatch).url(url);
    rc.doPost(user, (result: any) => {
        if(result){
            callback && callback(true)
        }
        else{
            callback && callback(null);
        }
    }, false);
}



export function openCreateAccount(setIsCreateAccountVisible:React.Dispatch<boolean>,setIsLoginVisible:React.Dispatch<boolean>) {
    setIsCreateAccountVisible(true);
    setIsLoginVisible(false);
}

export function openLogin(setIsCreateAccountVisible:React.Dispatch<boolean>,setIsLoginVisible:React.Dispatch<boolean>){
    setIsLoginVisible(true);
    setIsCreateAccountVisible(false);
}