import React, {useEffect, useState} from 'react';
import Header from "../../components/header/Header";
import {
    areNumberedInTicketList,
    checkTicketsAvailableAndGoToBuy,
    loadEventDetail,
    loadTicketEventByDate
} from "../../solutions/controllers/EventDetailsController";
import {IEventDetails} from "../../solutions/interfaces/IEventDetails";
import styles from './EventDetails.module.css';
import commonStyles from '../../components/commonStyles/CommonStyles.module.css';
import SimpleMap from "../../components/map/Map";
import {Col, Row, Button, Modal, Space} from "antd";
import {ITicketQuantities} from "../../solutions/interfaces/ITicketQuantities";
import locationImage from '../../assets/grey_location.svg';
import calendarImage from '../../assets/material-symbols_calendar-month.svg';
import clockImage from '../../assets/tabler_clock-hour-1.svg';
import ticketImage from '../../assets/ion_ticket.svg';
import infoImage from '../../assets/info.svg';
import addImage from '../../assets/add.svg';
import {useDispatch, useSelector} from "react-redux";
import {useMatches, useNavigate} from "react-router-dom";
import {IEventDate} from "../../solutions/interfaces/IEventDate";
import {ITicketByEventDate} from "../../solutions/interfaces/ITicketByEventDate";
import {
    calculateMoneyAmountToBuy,
    checkIBuyButtonIsAvailable,
    getTicketListPerQuantity,
    updateTicketQuantities
} from "../../solutions/controllers/BuyController";
import {ITicket} from "../../solutions/interfaces/ITicket";
import {formatDateToString, formateDatesToString, formatHourToString} from "../../solutions/Utils";
import {Select} from 'antd';
import minusBtn from '../../assets/minusBtn.png';
import plusBtn from '../../assets/plusBtn.png';
import {SeatReservation} from "../../components/seatReservation/SeatReservation";
import {Seat} from "../../components/seatReservation/model/Seat";

export default function EventDetails() {

    const [event, setEvent] = useState<IEventDetails | undefined>(undefined);
    const [dates, setDates] = useState<IEventDate[]>([])
    const [selectedDate, setSelectedDate] = useState<IEventDate | undefined>(undefined);
    const [eventDateFormatted, setEventDateFormatted] = useState('');
    const [ticketQuantities, setTicketQuantities] = useState<ITicketQuantities>({});
    const [numberedTickets, setNumberedTickets] = useState<Seat[]>([]);
    const [ticketEventByDates, setTicketEventByDates] = useState<ITicketByEventDate | undefined>(undefined)
    const [canBuy, setCanBuy] = useState(false);
    const [isModalSetaSelectionOpen, setIsModalSeatSelectionOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const initialMatches: any = useMatches();
    const navigate = useNavigate();
    const auth = useSelector((state: any) => state.auth);
    const [areNumbered, setAreNumbered] = useState(false);


    useEffect(() => {
        loadEventDetail(initialMatches[0].params.eventId, setEvent, dispatch);
    }, [initialMatches[0].params.eventId]);

    useEffect(() => {
        if (event && event.dates) {
            setDates(event.dates)
            const selectedDateObj = event.dates[0];
            if (selectedDateObj) {
                setSelectedDate(selectedDateObj);
            }
            formateDatesToString(event.dates, setEventDateFormatted)
        }

    }, [event])

    useEffect(() => {
        if (dates) {
            const selector = document.querySelector('.ant-select-selector');
            const selectorArrow = document.querySelector('.ant-select-arrow');
            if (selector && selectorArrow) {
                // @ts-ignore
                selector.style.background = '#2A2727';
                // @ts-ignore
                selector.style.color = 'white';
                // @ts-ignore
                selectorArrow.style.color = 'white';
            }
        }
    }, [dates]);

    useEffect(() => {
        if (selectedDate && selectedDate.eventdateid) {
            //@ts-ignore
            loadTicketEventByDate(selectedDate.eventdateid, ticketEventByDates, setTicketEventByDates, dispatch);
        }
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate && selectedDate.eventdateid && ticketEventByDates && ticketEventByDates[selectedDate.eventdateid]) {
            //@ts-ignore
            areNumberedInTicketList(setAreNumbered, ticketEventByDates[selectedDate.eventdateid]);
        }
    }, [ticketEventByDates])

    useEffect(() => {
        if (ticketEventByDates && ticketQuantities && selectedDate) {
            checkIBuyButtonIsAvailable(ticketQuantities, numberedTickets, setCanBuy)
            //@ts-ignore
            const tickets: ITicket[] = Object.keys(ticketEventByDates).flatMap(value => ticketEventByDates[value]);
            let amountToPay = calculateMoneyAmountToBuy(tickets, ticketQuantities, numberedTickets);
            setTotal(amountToPay)
        } else {
            setTotal(0)
        }
    }, [ticketQuantities, numberedTickets])


    const buyTicket = () => {
        checkTicketsAvailableAndGoToBuy(ticketQuantities, numberedTickets, (uuid: string) => {
            if (uuid) {
                goToBuy(uuid);
            }
        }, dispatch)
    }

    const goToBuy = (uuid: string) => {
        //@ts-ignore
        const tickets: ITicket[] = Object.keys(ticketEventByDates!).flatMap(value => ticketEventByDates![value]);
        let ticketList = getTicketListPerQuantity(tickets, ticketQuantities);
        navigate("/buy", {
            state:
                {
                    tickets: ticketList,
                    numberedTickets: numberedTickets,
                    uuid: uuid
                }
        })
    }

    const incrementTicketQuantity = (ticket: ITicket) => {
        let previousQuantity = ticketQuantities[ticket.id] || 0;
        previousQuantity++;
        updateTicketQuantities(ticket.id, previousQuantity, setTicketQuantities);
    };

    const decrementTicketQuantity = (ticket: ITicket) => {
        let previousQuantity = ticketQuantities[ticket.id] || 0;
        if (previousQuantity > 0) {
            previousQuantity--;
            updateTicketQuantities(ticket.id, previousQuantity, setTicketQuantities);
        }
    };

    function goToLoginRegister() {
        navigate("/loginRegister")
    }

    function goToMyCollection() {
        navigate("/collection")
    }

    function openSeatSelection() {
        setIsModalSeatSelectionOpen(true);
    }

    function seatReservationChange(seats: Seat[]) {
        console.log("seatReservationChange", seats)
        setNumberedTickets(seats)
    }

    function seatSelect(seat: Seat) {
        console.log("seatSelect", seat)
    }

    function seatRemove(seat: Seat) {
        console.log("seat", seat)
    }

    return (
        event ?
            <div className={styles.background}>
                <Modal width={'100%'} okText={"Guardar selección"} cancelText={"Cerrar sin guardar"}
                       open={isModalSetaSelectionOpen}
                       onCancel={() => {
                           setNumberedTickets([])
                           setIsModalSeatSelectionOpen(false)
                       }
                       }
                       onOk={() => setIsModalSeatSelectionOpen(false)}
                >
                    {selectedDate && <SeatReservation
                        eventdate={selectedDate!.eventdateid}
                        onSeatReservationChanged={seatReservationChange}
                        onSelectSeat={seatSelect}
                        onRemoveSeat={seatRemove}></SeatReservation>}

                </Modal>
                <Row style={{marginTop: 0}} justify={'center'}>
                    <Col xs={15} sm={15} md={16} lg={17} xl={18} xxl={18} style={{textAlign: "center"}}>
                        <p className={styles.eventTitle}>{event.title}</p>
                    </Col>
                    <Col xs={8} sm={8} md={7} lg={6} xl={5} xxl={5} offset={1}
                         style={{textAlign: "center", display: "flex", alignItems: "center"}}>
                        {auth && auth.logged ?
                            <Button onClick={goToMyCollection} type={'default'} className={commonStyles.buttonStyle}>Mis
                                entradas
                            </Button>
                            :
                            <Button onClick={goToLoginRegister} type={'default'} className={commonStyles.buttonStyle}>Login/Registro
                            </Button>
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: 0}}>
                    <Col
                        xs={{span: 0, offset: 0}} sm={{span: 0, offset: 0}} md={{span: 6, offset: 1}}
                        lg={{span: 4, offset: 2}} xl={{span: 4, offset: 2}} xxl={{span: 4, offset: 4}}>
                        <Row className={styles.imageContainer}>
                            <img alt="example" src={event ? "data:image/png;base64," + event.image : ''}
                                 className={styles.eventImage}/>
                        </Row>
                        <Row align={'middle'} className={styles.boxContainer}>
                            <img alt="example" src={locationImage} className={styles.boxImage}/>
                            <p className={styles.textBox}>{event.town.town}</p>
                        </Row>
                        <Row align={'middle'} className={styles.boxContainer}>
                            <img alt="example2" src={clockImage} className={styles.boxImage}/>
                            <p className={styles.textBox}>{selectedDate ? formatHourToString(selectedDate.date) : ''}</p>
                        </Row>
                        <Row align={'middle'} className={styles.boxContainer}>
                            <img alt="example3" src={calendarImage} className={styles.boxImage}/>
                            <p className={styles.textBox}>{eventDateFormatted}</p>
                        </Row>
                    </Col>

                    {/*//Vista para movil*/}
                    <Col xs={{span: 23, offset: 1}} sm={{span: 23, offset: 1}} md={{span: 0, offset: 0}}
                         lg={{span: 0, offset: 0}} xl={{span: 0, offset: 0}} xxl={{span: 0, offset: 0}}>
                        <Row justify={"center"} style={{alignItems: "center"}}>
                            <Col span={10}>
                                <img alt="example" src={event ? "data:image/png;base64," + event.image : ''}
                                     className={styles.eventImageMobile}/>
                            </Col>
                            <Col span={14}>
                                <Row align={'middle'} className={styles.boxContainer}>
                                    <img alt="example" src={locationImage} className={styles.boxImage}/>
                                    <p className={styles.textBox}>{event.town.town}</p>
                                </Row>
                                <Row align={'middle'} className={styles.boxContainer}>
                                    <img alt="example2" src={clockImage} className={styles.boxImage}/>
                                    <p className={styles.textBox}>{selectedDate ? formatHourToString(selectedDate.date) : ''}</p>
                                </Row>
                                <Row align={'middle'} className={styles.boxContainer}>
                                    <img alt="example3" src={calendarImage} className={styles.boxImage}/>
                                    <p className={styles.textBox}>{eventDateFormatted}</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        xs={{span: 22, offset: 0}} sm={{span: 21, offset: 1}} md={{span: 14, offset: 1}}
                        lg={{span: 12, offset: 1}} xl={{span: 10, offset: 1}} xxl={{span: 8, offset: 1}}>
                        <>
                            {(dates.length > 1 && selectedDate) ? (
                                <Row className={styles.dateBox}>
                                    <p className={styles.ticketTitle}>Seleccione fecha</p>
                                    <Select
                                        defaultValue={selectedDate.eventdateid}
                                        onChange={(value, option) => {
                                            if ("dates" in event) {
                                                const selectedDateObj = event.dates.find(dateObj => dateObj.eventdateid === value);
                                                setSelectedDate(selectedDateObj);
                                            }
                                        }}
                                        className={styles.selectDateInput}
                                        style={{width: '100%'}}
                                        options={dates.map((d) => {
                                            return {
                                                label: formatDateToString(d.date),
                                                value: d.eventdateid
                                            }
                                        })}
                                    />
                                </Row>
                            ) : ''}

                            {selectedDate && (!ticketEventByDates || !ticketEventByDates[selectedDate.eventdateid] || ticketEventByDates[selectedDate.eventdateid].length === 0) ?
                                <Row className={styles.ticketBox}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{marginTop: 10}}>
                                        <p style={{width: '100% !important'}} className={styles.ticketTitle}>No hay
                                            entradas para la fecha seleccionada.</p>
                                    </Col>
                                </Row> : <></>}


                            {/*{areNumbered && selectedDate && ticketEventByDates && ticketEventByDates[selectedDate.eventdateid] &&*/}
                            {/*    <Row className={styles.ticketBox}>*/}
                            {/*        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} offset={0} style={{marginTop:10}} >*/}
                            {/*            <SeatReservation*/}
                            {/*                eventdate={selectedDate.eventdateid}*/}
                            {/*                onSeatReservationChanged={seatReservationChange}*/}
                            {/*                onSelectSeat={seatSelect}*/}
                            {/*                onRemoveSeat={seatRemove}*/}
                            {/*            />*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*}*/}

                            {selectedDate && ticketEventByDates && ticketEventByDates[selectedDate.eventdateid] ? (
                                    ticketEventByDates[selectedDate.eventdateid].map((ticket: any, index: number) => (
                                        <Row className={styles.ticketBox}>
                                            <Col xs={12} sm={12} md={14} lg={14} xl={14} xxl={14} offset={1}
                                                 style={{marginTop: 10}}>
                                                <Row>
                                                    <Col xs={4} sm={3} md={2} lg={2} xl={3} xxl={3}
                                                         style={{display: "flex", alignItems: "center"}}>
                                                        <img alt="ticketImg" src={ticketImage} className={styles.boxImage}
                                                             style={{marginLeft: 0}}/>

                                                    </Col>
                                                    <Col xs={0} sm={0} md={21} lg={21} xl={21} xxl={21}>
                                                        <p className={styles.ticketTitle}>{ticket.ticketname}</p>

                                                    </Col>
                                                    <Col xs={20} sm={20} md={0} lg={0} xl={0} xxl={0}>
                                                        <p className={styles.ticketTitle}
                                                           style={{fontSize: 14}}>{ticket.ticketname}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={0} sm={0} md={{span: 24, offset: 0}} lg={{span: 24, offset: 0}}
                                                         xl={{span: 24, offset: 0}} xxl={{span: 24, offset: 0}}>
                                                        <p className={styles.ticketMoreInfo}>{ticket.description}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={{span: 23, offset: 1}} sm={{span: 23, offset: 1}} md={0} lg={0}
                                                         xl={0} xxl={0}>
                                                        <p className={styles.ticketMoreInfo}
                                                           style={{fontSize: 14}}>{ticket.description}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {!ticket.numbered &&
                                                <Col xs={{span: 7, offset: 2}} sm={{span: 7, offset: 2}}
                                                     md={{span: 5, offset: 2}} lg={{span: 4, offset: 5}}
                                                     xl={{span: 4, offset: 5}} xxl={{span: 4, offset: 5}} style={{
                                                    marginTop: 10,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column"
                                                }}>
                                                 <span style={{
                                                     display: "flex",
                                                     alignItems: "center",
                                                     justifyContent: "space-around",
                                                     height: '100%'
                                                 }}>
                                                     <Space direction={"vertical"}>
                                                         <Row justify={'center'}>
                                                    {/*<p className={styles.ticketPrice}>{ticket.price.toFixed(2)}€</p>*/}
                                                             <p className={styles.ticketPrice}>{ticket.price}€</p>
                                                </Row>
                                                    <Row>
                                                        <Col xs={{span: 9}} sm={{span: 7}} md={{span: 7}} lg={{span: 9}}
                                                             xl={{span: 9}} xxl={{span: 9}}>
                                                            <Button type="text" className={styles.addLessTouchable}
                                                                    onClick={() => decrementTicketQuantity(ticket)}>
                                                                <img className={styles.addText} src={minusBtn}/>
                                                                {/*<span className={styles.lessText}>-</span>*/}
                                                            </Button>
                                                        </Col>
                                                        <Col xs={{span: 4, offset: 0}} sm={{span: 5, offset: 0}}
                                                             md={{span: 5, offset: 0}} lg={{span: 5, offset: 0}}
                                                             xl={{span: 5, offset: 0}}
                                                             xxl={{span: 5}}
                                                             style={{textAlign: "center", marginTop: 7, marginLeft: 0}}>
                                                            <span
                                                                className={styles.ticketQuantity}>{ticketQuantities[ticket.id] || 0}</span>
                                                        </Col>
                                                        <Col xs={{span: 9}} sm={{span: 7}} md={{span: 7}}
                                                             lg={{span: 7, offset: 0}} xl={{span: 7, offset: 0}}
                                                             xxl={{span: 7, offset: 0}}>
                                                            <Button type="text" className={styles.addLessTouchable}
                                                                    onClick={() => incrementTicketQuantity(ticket)}>
                                                                <img className={styles.addText} src={plusBtn}/>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                     </Space>
                                                 </span>

                                                </Col>
                                            }
                                            {ticket.numbered &&
                                                <Col xs={{span: 9, offset: 0}} sm={{span: 9, offset: 0}}
                                                     md={{span: 7, offset: 0}} lg={{span: 6, offset: 1}}
                                                     xl={{span: 6, offset: 1}} xxl={{span: 6, offset: 1}}
                                                     style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                                                    <Row justify={"center"}>
                                                        <Button type="text" className={commonStyles.buttonStyle}
                                                                onClick={openSeatSelection}>
                                                            ELEGIR ASIENTO
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    ))
                                )
                                : (
                                    ''
                                )}

                            <Row className={styles.buttonBox}>
                                <Col xs={{span: 7, offset: 1}} sm={{span: 6, offset: 1}} md={{span: 3, offset: 1}}
                                     lg={{span: 3, offset: 1}} xl={{span: 3, offset: 1}} xxl={{span: 3, offset: 1}}>
                                    <Button type={'default'} onClick={() => navigate("/")}
                                            className={commonStyles.buttonStyle}>Volver</Button>
                                </Col>
                                <Col xs={{span: 9, offset: 6}} sm={{span: 6, offset: 10}} md={{span: 6, offset: 13}}
                                     lg={{span: 6, offset: 13}} xl={{span: 6, offset: 13}} xxl={{span: 6, offset: 13}}>
                                    <Button type={'default'} disabled={!canBuy} onClick={buyTicket}
                                            className={commonStyles.buttonStyle}>{total.toFixed(2)}€ - Continuar
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    </Col>
                </Row>

                <Row justify={'center'} style={{marginTop: 30}}>
                    <Col xs={{span: 23}} sm={{span: 23}} md={{span: 21}} lg={{span: 19}} xl={{span: 17}}
                         xxl={{span: 16}} className={styles.descriptionBox}>
                        <div dangerouslySetInnerHTML={{__html: event.description ? event.description : `<p></p>`}}
                             className={styles.descriptionContent}></div>
                    </Col>
                </Row>

                <Row justify={'center'} style={{marginTop: 30}}>
                    <Col xs={{span: 23}} sm={{span: 23}} md={{span: 21}} lg={{span: 19}} xl={{span: 17}}
                         xxl={{span: 16}} className={styles.descriptionBox}>
                        <SimpleMap longitude={event.longitude} latitude={event.latitude}/>
                    </Col>
                </Row>

                <Row style={{paddingBottom: 30}} justify={'center'} align={'middle'}>
                    <Col xxl={1}>
                        <div className={styles.addImageContainer}>
                            <img width={24} height={24} src={addImage} alt={'addImage'}/>
                        </div>

                    </Col>
                    <Col xs={{span: 18}} sm={{span: 16}} md={{span: 14}} lg={{span: 12}} xl={{span: 10}}
                         xxl={{span: 8}}>
                        <h1 className={styles.questionText}>¿Quieres conocer más eventos similares
                            de {event.promoterName}?</h1>
                    </Col>
                </Row>
            </div>
            : <></>
    );
}