import {Button, Col, DatePicker, Form, Input, message, Row, Space} from "antd";
import LoginForm from "../../components/loginForm/LoginForm";
import RegisterForm from "../../components/registerForm/RegisterForm";
import React, {useEffect, useState} from "react";
import {IUser} from "../../solutions/interfaces/IUser";
import {getProfile, openCreateAccount, openLogin} from "../../solutions/controllers/UserController";
import styles from "./Buy.module.css";
import {ITicketInfo} from "../../solutions/interfaces/ITicketInfo";
import {useLocation, useNavigate} from "react-router-dom";
import copyTitularData, {
    areNominativeInTicketList, buyTickets,
    calculateTotalToPayWithTaxes,
    changeTicketInfo
} from "../../solutions/controllers/BuyController";
import _ from "lodash";
import locale from 'antd/es/date-picker/locale/es_ES';
import {useDispatch, useSelector} from "react-redux";
import {FieldData} from "rc-field-form/lib/interface";
import {Simulate} from "react-dom/test-utils";
import emptied = Simulate.emptied;
const dateFormat = 'DD/MM/YYYY';

type FieldType = {
    firstName1?: string;
    lastName1?: string;
    birthdate1?: string;
    email1?: string;
    discountCode?:string;
    cardHolder?:string;
    cardNumber?:number;
    expiry:number;
    cvc:number;
    phoneNumber1?: number;
    postalCode1?: number;
};
export default function BuyTest(){
    useEffect(() => {
        //@ts-ignore

    }, []);



    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const inputValidator = () => {
        // Should validate input before sending form
        return true;
    };

    /*
   * Listen to reception of operation ID
   */
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('message', async (event: any) => {

        //@ts-ignore
        // storeIdOper(event, 'token', 'errorCode', 'inputValidator');
    });

    useEffect(() => {
        //@ts-ignore
         getInSiteForm('card-form', '', '', '', '', 'Texto botón pago', '347486946', '2', 'ped4227', 'ES', true, false, 'twoRows');
        //getInSiteForm('card-form', '', '', '', '', 'Texto botón pago', '347486946', '2', 'ped4227');

    }, []);

    // @ts-ignore
    return (
        // <div className={isLoginVisible?styles.loginBackground:styles.registerBackground}>
        <div>
            <div id="card-form"/>
            <input type="hidden" id="token" ></input>
            <input type="hidden" id="errorCode" ></input>
        </div>
    );
}