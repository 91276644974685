import styles from "./LoginRegister.module.css";
import React, { useEffect, useState} from 'react';
import LoginForm from "../../components/loginForm/LoginForm";
import {IUser} from "../../solutions/interfaces/IUser";
import {Button, Col, Form, message, Row} from "antd";
import {openCreateAccount, openLogin, registerUser} from "../../solutions/controllers/UserController";
import RegisterForm from "../../components/registerForm/RegisterForm";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

export default function LoginRegisterUser(){
    const [profile, setProfile] = useState<IUser>();
    const [isCreateAccountVisible, setIsCreateAccountVisible] = useState(true);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function onCreateAccountDialog() {
        openCreateAccount(setIsCreateAccountVisible,setIsLoginVisible);
    }
    function onOpenLoginDialog(){
        openLogin(setIsCreateAccountVisible,setIsLoginVisible);

    }

    function onRegister(){
        form.validateFields().then(value => {
            const userInfo = {
                name: value.firstName,
                email: value.email,
                password: value.password,
                birthdate: value.birthdate.unix() *1000,
                zipCode: value.postalCode,
                phone: value.phoneNumber
            }

            registerUser(userInfo, (success: any) =>{
                if(success){
                    message.success("Registrado correctamente, ya puede iniciar sesión");
                    onOpenLoginDialog();
                }
                else{
                    message.error("Se ha producio un error durante el registro, inténtelo de nuevo o contacte con el servicio técnico");
                }
            }, dispatch);

        }).catch(reason => {
            message.error("Revise los errores en el formulario")
        })
    }

    return (
        <div className={isLoginVisible?styles.loginBackground:styles.registerBackground}>
            <Row justify={'center'} style={{paddingTop:30}}>
                <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                <Button type={'default'} className={isLoginVisible?styles.buttonVisible:styles.buttonNotVisible} onClick={onCreateAccountDialog}>NUEVO USUARIO</Button>
            </Col>
                <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3} offset={1}>
                    <Button type={'default'} className={isCreateAccountVisible?styles.buttonVisible:styles.buttonNotVisible}  onClick={onOpenLoginDialog}>TENGO CUENTA</Button>
                </Col>
            </Row>

            <div style={{paddingTop:30}}></div>
            {isLoginVisible?(
                <LoginForm loginSuccessCallback={() => {
                    message.success('Sesión iniciada correctamente');
                    navigate("/");
                }}></LoginForm>
            ):null}
            {isCreateAccountVisible?(
                <>
                    <RegisterForm form={form} isBuy={false}></RegisterForm>
                    <Row justify={'center'} style={{paddingTop:30,paddingBottom:30}}>
                        <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                            <Button type={'default'} className={styles.buttonVisible} onClick={onRegister}>CONTINUAR</Button>
                        </Col>
                    </Row>
                </>
            ):null}
        </div>
    );
}