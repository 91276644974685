import {Card, Col, Row, Space} from "antd";
import React, { useEffect, useState} from 'react';
import './Footer.module.css';
import styles from './Footer.module.css';
import instagramImage from '../../assets/instagram.svg';
import twitterImage from '../../assets/twitter.svg';
import tiktokImage from '../../assets/tiktok.svg';
import linkedinImage from '../../assets/linkedin.svg';


// @ts-nocheck
export default function Footer(){
    return (
        <div style={{background: "black"}}>
            <Row>
                <Col style={{paddingLeft:10}} className={styles.dataSeparation} xs={12} sm={8} md={9} lg={10} xl={10} xxl={10}>
                    <p className={styles.textColor}>Contacto</p>
                    <p className={styles.textColor}>support@nftmyticket.com</p>
                    <p className={styles.textColor}>Partners</p>
                    <p className={styles.textColor}>partners@nftmyticket.com</p>
                </Col>
                <Col xs={0} sm={8} md={6} lg={4} xl={4} xxl={4}>
                    <span style={{display: "flex", alignItems:"center", justifyContent: "space-around", height: '100%'}}>
                        <Space direction={"horizontal"} style={{display: "flex"}}>
                        <a href="https://instagram.com/tixxlab.es"><img className={styles.imageBackground} style={{height: 35}} src={instagramImage}/></a>
                        <a href="https://x.com/tixxlab?s=21&t=dUN7Dj7CuNCq_L1-ebypiw"><img className={styles.imageBackground} style={{height: 35}} src={twitterImage}/></a>
                        <a href="https://www.linkedin.com/company/tixxlab"><img className={styles.imageBackground} style={{height: 35}} src={linkedinImage}/></a>
                        <img className={styles.imageBackground} style={{height: 35}} src={tiktokImage}/>
                    </Space>
                    </span>
                </Col>
                <Col style={{paddingRight:10, textAlign: "right"}} className={styles.dataSeparation} xs={12} sm={8} md={9} lg={10} xl={10} xxl={10}>
                    {/*<p><a className={styles.textColor}>Ayuda</a></p>*/}
                    <p><a onClick={() =>  window.open("/legalAdvice", "_blank")} className={styles.textColor}>Políticas de aviso legal</a></p>
                    <p><a onClick={() =>  window.open("/privacyPolicy", "_blank")} className={styles.textColor}>Políticas de privacidad</a></p>
                    <p><a onClick={() =>  window.open("/cookiesPolicy", "_blank")} className={styles.textColor}>Política de cookies</a></p>
                    <p><a onClick={() =>  window.open("/termAndConditions", "_blank")} className={styles.textColor}>Términos y condiciones</a></p>
                </Col>
                <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0} style={{paddingBottom: 20}}>
                    <span style={{display: "flex", alignItems:"center", justifyContent: "space-around"}}>
                        <Space direction={"horizontal" } style={{display: "flex"}}>
                        <a href="https://instagram.com/tixxlab.es"><img className={styles.imageBackground} style={{height: 35}} src={instagramImage}/></a>
                        <a href="https://x.com/tixxlab?s=21&t=dUN7Dj7CuNCq_L1-ebypiw"><img className={styles.imageBackground} style={{height: 35}} src={twitterImage}/></a>
                        <a href="https://www.linkedin.com/company/tixxlab"><img className={styles.imageBackground} style={{height: 35}} src={linkedinImage}/></a>
                        <img className={styles.imageBackground} style={{height: 35}} src={tiktokImage}/>
                    </Space>
                    </span>
                </Col>
            </Row>
        </div>
    );
}