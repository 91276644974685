import {Button, Checkbox, Col, DatePicker, Form, Input, message, Row, Space} from "antd";
import LoginForm from "../../components/loginForm/LoginForm";
import RegisterForm from "../../components/registerForm/RegisterForm";
import React, {useEffect, useState} from "react";
import {IUser} from "../../solutions/interfaces/IUser";
import {getProfile, openCreateAccount, openLogin} from "../../solutions/controllers/UserController";
import styles from "./Buy.module.css";
import {ITicketInfo} from "../../solutions/interfaces/ITicketInfo";
import {useLocation, useNavigate} from "react-router-dom";
import copyTitularData, {
    areNominativeInTicketList, buyTickets,
    calculateTotalToPayWithTaxes,
    changeTicketInfo
} from "../../solutions/controllers/BuyController";
import _ from "lodash";
import locale from 'antd/es/date-picker/locale/es_ES';
import {useDispatch, useSelector} from "react-redux";
import {FieldData} from "rc-field-form/lib/interface";
import {Simulate} from "react-dom/test-utils";
import emptied = Simulate.emptied;
import {Seat} from "../../components/seatReservation/model/Seat";
const dateFormat = 'DD/MM/YYYY';

type FieldType = {
    firstName1?: string;
    lastName1?: string;
    birthdate1?: string;
    email1?: string;
    discountCode?:string;
    cardHolder?:string;
    cardNumber?:number;
    expiry:number;
    cvc:number;
    phoneNumber1?: number;
    postalCode1?: number;
};
export default function Buy(){
    const [profile, setProfile] = useState<IUser>();
    const [isCreateAccountVisible, setIsCreateAccountVisible] = useState(true);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [form] = Form.useForm();
    const [ticketList, setTicketList] = useState<ITicketInfo[]>([]);
    const [groupedTickets, setGroupedTickets] = useState<any[]>([]);
    const [fieldsData, setFieldsData] = useState<FieldData[]>([]);
    const [total, setTotal] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [areNominative,setAreNominative] = useState(false);
    const { state } = useLocation(); // state is any or unknown
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state : any) => state.auth);

    function onCreateAccountDialog() {
        openCreateAccount(setIsCreateAccountVisible,setIsLoginVisible);
    }
    function onOpenLoginDialog(){
        openLogin(setIsCreateAccountVisible,setIsLoginVisible);
    }

    useEffect(() => {
        let uuid = localStorage.getItem('frontId');
       if(state && state.tickets && uuid != null && uuid === state.uuid){
           let aux = _.groupBy(state.tickets, ticket => ticket.ticket.id);
           const groupTicket : any[] = [];
           Object.keys(aux).forEach((value, index, array) => {
               groupTicket.push({
                   num: aux[value].length,
                   // @ts-ignore
                   name: aux[value][0].ticket.ticketname,
                   //@ts-ignore
                   price: aux[value][0].ticket.price
               });
           })

           const newTicketList = [...state.tickets];

           state.numberedTickets.forEach((t: Seat) => {
               groupTicket.push({
                   num: 1,
                   name: t.description,
                   price: t.price
               });

               newTicketList.push({
                   ticket: {
                       nominative: t.nominative,
                       id: t.ticketeventid,
                   },
                   gift: false,
                   seatid: t.id
               });
           })

           setGroupedTickets(groupTicket);
           calculateTotalToPayWithTaxes(state.tickets, state.numberedTickets, setTotal, setTaxes);
           areNominativeInTicketList(setAreNominative, state.tickets, state.numberedTickets);
           setTicketList(newTicketList);
       }
    }, [state]);

    useEffect(()=>{
        const datePickerInput = document.querySelector('#assistantForm_birthdate1');
        if(datePickerInput){
            // @ts-ignore
            datePickerInput.style.color= 'white';
        }

        if(ticketList){
            const fields : any[] = [];

            ticketList.forEach((ticket, index) => {
                const datePickerInput = document.querySelector('#assistantForm_birthdate1'+index);
                if(datePickerInput){
                    // @ts-ignore
                    datePickerInput.style.color= 'white';
                }

                if(ticket.ticket.nominative)
                {
                    console.log("Ticket ",ticket)
                    fields.push({
                        name: ["email1" + index],
                        value: ticketList[index] ? ticketList[index].email : ''
                    });

                    fields.push({
                        name: ["firstName1" + index],
                        value: ticketList[index] ? ticketList[index].firstName : ''
                    });

                    // console.log("birthdate "+ index, ticketList[index].birthdate)
                    //
                    // fields.push({
                    //     name: ["birthdate1" + index],
                    //     value: ticketList[index] ? ticketList[index].birthdate : ''
                    // });

                    fields.push({
                        name: ["phoneNumber1" + index],
                        value: ticketList[index] ? ticketList[index].phoneNumber : ''
                    });

                    fields.push({
                        name: ["postalCode1" + index],
                        value: ticketList[index] ? ticketList[index].postalCode : ''
                    });
                }

            })
            setFieldsData(fields);
        }

    }, [ticketList])

    useEffect(() => {
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);
        }
    }, [auth]);

    function onChangeTicketInfo (field: string, value: any, index: number) {
        changeTicketInfo(field,value,index,ticketList,setTicketList);
    }

    function onCopy(index: number) {
        if (profile){
            copyTitularData(index,profile,ticketList,setTicketList);
        }
    }

    function onBuy(values: any) {

        console.log("Values ",values);

        if(form){
            form.validateFields().then(value => {

                console.log("Values dentro del validate", value)
                let logguedUser = null;
                let invitedUser = null;

                if(!value.terms){
                    message.error("Debe aceptar los términos y condiciones");
                    return;
                }

                if(profile){
                    logguedUser = {
                        email: profile.email
                    }
                }
                else{
                    invitedUser = {
                        email: value.email,
                        name: value.firstName,
                        birthdate: value.birthdate.unix() *1000,
                        zipCode: value.postalCode,
                        phone: value.phoneNumber
                    }
                }

                buyTickets(ticketList, logguedUser, invitedUser, () => {
                    message.success("Entradas compradas corrrectamente, puedes verlas en tu colección")
                    navigate("/");
                }, dispatch)
            })
                .catch(reason => {
                    message.error("Debe rellenar todos los campos");
                    console.log("Error de validación ",reason)
                })
        }

    }

    // @ts-ignore
    return (
        <div className={isLoginVisible?styles.loginBackground:styles.registerBackground}>
            {!profile ?
                <Row justify={'center'} style={{paddingTop:30}}>
                    <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                        <Button type={'default'} className={isLoginVisible?styles.buttonVisible:styles.buttonNotVisible} onClick={onCreateAccountDialog}>NUEVO USUARIO</Button>
                    </Col>
                    <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3} offset={1}>
                        <Button type={'default'} className={isCreateAccountVisible?styles.buttonVisible:styles.buttonNotVisible}  onClick={onOpenLoginDialog}>TENGO CUENTA</Button>
                    </Col>
                </Row>
                :
                <div style={{paddingTop:30}}>
                    <Row justify={'center'} style={{paddingBottom: 20}}>
                        <h1 className={styles.title}>DATOS DEL COMPRADOR</h1>
                    </Row>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                            <Form.Item
                                label={<span className={styles.label}>CORREO ELECTRÓNICO DEL COMPRADOR</span>}
                                //@ts-ignore
                                name={"emailBuyer" }
                                style={{ width: '100%' }}
                                initialValue={profile.email}
                            >
                                <p className={styles.buyerInputData} style={{width:'100%'}}>{profile.email}</p>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                            <Form.Item
                                label={<span className={styles.label}>NOMBRE Y APELLIDOS DEL COMPRADOR</span>}
                                name={"nameBuyer"}
                                style={{ width: '100%', paddingLeft: 7 }}
                                initialValue={profile.name}
                            >
                                <p className={styles.buyerInputData} style={{width:'100%'}}>{profile.name}</p>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                }

            <div style={{paddingTop:30}}></div>
            {isLoginVisible?(
                <LoginForm loginSuccessCallback={() => {
                    message.success("Sesión iniciada correctamente");
                    onCreateAccountDialog();
                }}/>
            ):null}
            {isCreateAccountVisible?(
                <>
                    {!profile && <RegisterForm isBuy={true} form={form}></RegisterForm>}
                    {areNominative &&
                        <>
                            <Row justify={'center'}>
                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                            </Row>
                            <Row justify={'center'}>
                                <h1 className={styles.title}>DATOS DE LOS ASISTENTES</h1>
                            </Row>
                            <Form
                                form={form}
                                name="assistantForm"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width:'100%' }}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                fields={fieldsData}
                            >

                                {ticketList.map((ticket, index) =>
                                {
                                    console.log("index "+index+", ticketList[index].email || ''", ticketList[index].email || '')
                                    return (ticket.ticket.nominative ?
                                        <>
                                            <Row justify={'center'}>
                                                <h1 className={styles.assistantTitle}>ASISTENTE {index+1}</h1>
                                            </Row>
                                            <Row justify={'center'} key={'ticketname'+index}>
                                                <h1 className={styles.assistantTicketTitle}>{ticket.ticket.ticketname}</h1>
                                            </Row>
                                            <Row justify={'center'} key={'description'+index}>
                                                <h1 className={styles.assistantTicketTitle}>{ticket.ticket.description} {ticket.ticket.price}€</h1>
                                            </Row>
                                            {profile &&
                                                <Row justify={'center'}>
                                                    <Button
                                                        className={styles.copyTitularDataButton}
                                                        onClick={()=>onCopy(index)}
                                                        key={'touchableCopy'+index}
                                                    >
                                                        COPIAR DATOS DEL TITULAR
                                                    </Button>
                                                </Row>
                                            }
                                            <Row justify={'center'}>
                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                    <Form.Item
                                                        label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                                                        //@ts-ignore
                                                        name={"email1" + index}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: 'Please input your email1!', type:'email' }]}
                                                    >
                                                        <Input value={ticketList[index].email || ''} className={styles.input} placeholder={''} style={{width:'100%'}}
                                                               onChange={(e) => onChangeTicketInfo("email", e.target.value, index)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify={'center'}>
                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                    <Form.Item
                                                        label={<span className={styles.label}>NOMBRE Y APELLIDOS DEL TITULAR</span>}
                                                        name={"firstName1"+index}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: 'Please input the assistant name!', type:'string' }]}
                                                    >
                                                        <Input value={ticketList[index].firstName || ''}
                                                            className={styles.input} placeholder={''} style={{width:'100%'}}
                                                               onChange={(e) => onChangeTicketInfo("firstName", e.target.value, index)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row justify={'center'}>
                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                    <Form.Item
                                                        label={<span className={styles.label}>FECHA DE NACIMIENTO</span>}
                                                        name={"birthdate1"+index}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: 'Please input the assistant 1 birthdate!' }]}
                                                    >
                                                        <DatePicker locale={locale} format={dateFormat} placeholder={''} className={styles.datepicker}  style={{width:'100%', color:'white'}}
                                                                    onOk={(value) => onChangeTicketInfo("birthdate", value.unix() * 1000, index)}
                                                                    onSelect={(value) => onChangeTicketInfo("birthdate", value.unix() * 1000, index)}
                                                                    onChange={(value) =>
                                                                        onChangeTicketInfo("birthdate", value ? (value.unix() * 1000) : null, index)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify={'center'}>
                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                    <Form.Item
                                                        label={<span className={styles.label}>TELÉFONO</span>}
                                                        name={"phoneNumber1"+index}
                                                        style={{ width: '100%', color:'white' }}
                                                    >
                                                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%',color:'white'}}
                                                               onChange={(e) => onChangeTicketInfo("phoneNumber", e.target.value, index)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify={'center'}>
                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                    <Form.Item
                                                        label={<span className={styles.label}>CÓDIGO POSTAL</span>}
                                                        name={"postalCode1"+index}
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: true, message: 'Please input the assistant 1 postal code!' }]}
                                                    >
                                                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}
                                                               onChange={(e) => onChangeTicketInfo("postalCode", e.target.value, index)}

                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </> : <></> )
                                }
                                    )}
                            </Form>

                        </>
                    }

                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Row justify={'center'}>
                        <h1 className={styles.title}>RESUMEN DE TU PEDIDO</h1>
                    </Row>
                    <Row justify={'center'}>
                        <p className={styles.summaryBoldText}>Nº de tickets: {ticketList.length} ticket(s)</p>
                    </Row>

                    {groupedTickets && groupedTickets.map((ticket, index) =>
                        <Row justify={'center'}>
                             <p className={styles.summaryText}>{ticket.num} x {ticket.name} {ticket.price}€</p>
                        </Row>
                    )}

                    <Row justify={'center'}>
                        <p className={styles.summaryText}>Gastos de gestión: {taxes}€</p>
                    </Row>
                    <Row justify={'center'}>
                        <p className={styles.summaryText}>Total: {total}€</p>
                    </Row>
                    <Form
                        form={form}
                        name="payForm"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ width:'100%' }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                    >
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>CÓDIGO DE DESCUENTO</span>}
                                    name="discountCode"
                                    style={{ width: '100%' }}
                                    rules={[{ required: false }]}
                                >
                                    <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>NÚMERO DE LA TARJETA</span>}
                                    name="cardNumber"
                                    style={{ width: '100%' }}
                                    // rules={[{ required: true, message:'Please input the card number!'}]}
                                >
                                    <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>NOMBRE DEL TITULAR DE LA TARJETA</span>}
                                    name="cardHolder"
                                    style={{ width: '100%' }}
                                    // rules={[{ required: true, message:'Please input the card owner!'}]}
                                >
                                    <Input className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>FECHA DE CADUCIDAD</span>}
                                    name="expiry"
                                    style={{ width: '100%' }}
                                    // rules={[{ required: true, message:'Please input the expiry' }]}
                                >
                                    <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>CVC</span>}
                                    name="cvc"
                                    style={{ width: '100%' }}
                                    // rules={[{ required: true, message:'Please input the CVC' }]}
                                >
                                    <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                    <Checkbox><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row justify={'center'} style={{paddingTop:30,paddingBottom:30}}>
                        <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                            <Button type={'default'} className={styles.buttonVisible} onClick={onBuy}>CONFIRMAR PAGO</Button>
                        </Col>
                    </Row>
                </>
            ):null}
        </div>
    );
}